<template>
  <v-container
    fluid
    tag="section"
  >
<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
        <p v-html="msg_retorno"></p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>



<base-material-card
      icon="mdi-incognito"
      title="Atividades em Análise"
      class="px-5 py-3"
    >

     <v-data-table
      :headers="header_atividades"
      :items="atividades"
      :items-per-page="12"
      class="elevation-1">


 <template v-slot:item="row">
          <tr>
            <td style="width: 10%;font-size:14px;">{{row.item.data_insert}}</td>
            <td style="width: 20%;font-size:14px;">{{row.item.evento}} - Contrato: {{row.item.contrato}}</td>
            <td style="width: 8%;font-size:14px;">{{row.item.pontos}} ({{row.item.bloqueado}})</td>
            <td style="width: 25%;font-size:14px;">{{row.item.promotor}}</td>
            <td style="width: 15%;font-size:14px;">{{row.item.alerta}}</td>

            <td style="width: 20%;font-size:14px;">
            <v-btn elevation="2" color="orange" fab small label="Informação" class="ma-2 white--text"    :loading="btn_status" @click="informacoes_analise(row.item)">                    
              <v-icon  dark>mdi-information</v-icon>      
            </v-btn>

            <v-btn elevation="2" color="success" fab small label="Aprovar" class="ma-2 white--text" :loading="btn_status"  v-if="row.item.alerta == 'EM ANÁLISE' ||  row.item.alerta == 'EM LIBERAÇÃO'" @click="confirmAutorizar(row.item)">                    
              <v-icon  dark>mdi-check</v-icon>      
            </v-btn>

             <v-btn elevation="2" color="red" fab small label="Recusar" class="ma-2 white--text"   :loading="btn_status"  v-if="row.item.alerta == 'EM ANÁLISE' ||  row.item.alerta == 'EM LIBERAÇÃO'" @click="confirmRecusar(row.item)">                    
              <v-icon  dark>mdi-close</v-icon>      
            </v-btn>

                
            </td>
          </tr>
      </template>
    </v-data-table>
    </base-material-card>

     <template>
      <ConfirmDlg ref="confirm" />
      </template>

  

    

  </v-container>


</template>
<script>
  import Promotores from '../../../services/promotores'
  import Roles from '../../../services/roles'

  export default {

     components: {
      ConfirmDlg: () => import("../component/ConfirmDlg"),
    },

     created() {
          if(this.$store.state.auth.status.loggedIn == false){
           this.$router.push('/')
          }
      },

    data: () => ({
      loading_roles: false,
      btn_status: false,
      alert_msg: false,
      alert_msg_descricao: '',
      loading: false,
      msg_retorno: '',
      atividades:[],
      header_atividades:[
          { text: 'Data', value: 'data_insert' },
          { text: 'Evento', value: 'evento' },
          { text: 'Pontos / Bloqueado', value: 'pontos', value:'bloqueado' },
          { text: 'Promotor', value: 'promotor' },
          { text: 'Msg', value: 'alerta' },

      ],
      controle_premiacao:{
        id_promotor: '',
        id_classificacao_pontos: '',
        token: '',
        evento: '',
      },
      signupRequest:{
          username: '',
          email: '',
          password: '',
          empresa: 'Previda',
      },
      msg_dialog: '',
      dialog: false,
      search: '',
      

    }),

    mounted () {
      this.listar();

    },


    methods: {
      listar () {
       this.loading = true

        Promotores.eventos_analise().then(response => {
            this.atividades = response.data
        }).catch(e => {
            if(e.response.status === 401){
            this.$store.dispatch('auth/logout');
            this.$router.push('/restrito/login');
            }


        }).finally(() => {
            this.loading = false

        })


      },


      clear () {       
        this.$refs.form.reset();
      },


      informacoes_analise(item){
        this.btn_status = true;
        Promotores.informacoes_analise(item.id).then(response => {
                if(response.status = 200){
                    this.alert_msg =  true;
                    this.msg_retorno = response.data.mensagem;
                }

          }).catch(e => {
                    this.btn_status = false;
                     if(e.response.status === 403){
                        alert('Usuário não tem permissão')
                      }

          }).finally(() => {
                    this.btn_status = false;

          })
      
      },



    async confirmAutorizar(item) {
         if (
          await this.$refs.confirm.open(
            "Mensagem",
            "Confirmar Autorização?"
          )
        ){
          this.autorizar(item);
        }
    },

      async confirmRecusar(item) {
         if (
          await this.$refs.confirm.open(
            "Mensagem",
            "Confirmar Recusa?"
          )
        ){
          this.recusar(item);
        }
    },

    
    recusar(item){
          this.btn_status = true;
            Promotores.recusar_evento(item.id).then(response => {
                        if(response.status = 200){
                            this.alert_msg =  true;
                            this.msg_retorno = response.data.mensagem;
                            this.listar();
                        }

          }).catch(e => {
                          this.btn_status = false;
                      if(e.response.status === 403){
                        alert('Usuário não tem permissão')
                      }

          }).finally(() => {
                        this.btn_status = false;
                        

          })
      },
      autorizar(item){
          this.btn_status = true;
            Promotores.autorizar_evento(item.id).then(response => {
                        if(response.status = 200){
                            this.alert_msg =  true;
                            this.msg_retorno = response.data.mensagem;
                            this.listar();
                        }

          }).catch(e => {
                          this.btn_status = false;
                      if(e.response.status === 403){
                        alert('Usuário não tem permissão')
                      }

          }).finally(() => {
                        this.btn_status = false;
                        

          })
      },

      setDisabled: function(status){
        return status
      },

      setEnable: function(status){
        return !status
      },

    }
  }

</script>
